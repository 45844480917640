import CallEffect from '@/components/callEffect';
import EmailButton from '@/components/emailButton';
import Form from '@/components/form';
import { ModalFormWrapper } from '@/components/form/modal';
import Loading from '@/components/loading';
import QRScanBox from '@/components/qrScanBox';
import TableWrapper from '@/components/tableWrapper';
import { useGraphQL } from '@/data';
import { CommerceRead, CommercesStatement } from '@/data/commerce/commerce.graphql';
import { EstimatesBalance } from '@/data/commerce/estimate.graphql';
import { InvoicesBalance } from '@/data/commerce/invoice.graphql';
import { OrdersBalance } from '@/data/commerce/order.graphql';
import { PaymentRead } from '@/data/commerce/payment.graphql';
import { StaffsRead } from '@/data/management/staff.graphql';
import PageGraphqlProvider from '@/data/query/pageGraphqlProvider';
import currencyFormat from '@/helpers/currencyFormat';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import AccountEmailView from '@/pages/admin/accounts/emailView';
import {
	EmailValuesType,
	getCommerceEmailValues,
	sendReminderCommercesEmail,
} from '@/pages/dashboard/commerce/invoices/syncAndPay';
import HouseAccountInvoicesStatement from '@/pages/p/commerce/emailView/houseAccountInvoicesStatement';
import InvoicesStatementView from '@/pages/p/commerce/emailView/invoicesStatementView';
import PaymentReceiptEmailView from '@/pages/p/commerce/emailView/paymentReceiptView';
import ReminderCommercesEmailView from '@/pages/p/commerce/emailView/reminderCommerces';
import useUserInfo from '@/providers/auth/useUserInfo';
import type {
	Client,
	HouseAccount,
	Order,
	Payment,
	QueryCommerceReadArgs,
	QueryEstimatesBalanceArgs,
	QueryPaymentReadArgs,
} from '@/types/schema';
import {
	Company,
	QueryCommercesStatementArgs,
	QueryHouseAccountInvoicesTotalsByMonthArgs,
	QueryInvoicesBalanceArgs,
	QueryOrdersBalanceArgs,
	QueryStaffsReadArgs,
} from '@/types/schema';
import { isEmailValid } from '@/utils/isValidEmail';
import { getBrowserTimezone } from '@/utils/timezone';
import wait from '@/utils/wait';
import { gql } from '@apollo/client';
import { Box, Paper, Stack, TableCell, Typography } from '@mui/material';
import axios from 'axios';
import { addDays, format } from 'date-fns';
import { isEmpty, startCase, toLower, upperFirst } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailComponent } from './email/emailComponent';

export function InvoicesReminderEmailModal( {
	invoices,
	subject,
}: {
	invoices: Order[],
	subject?: string
} ) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { user, staff } = useUserInfo();
	const timezone = getBrowserTimezone();
	
	return (
		<PageGraphqlProvider<Order, QueryCommerceReadArgs>
			queryKey='commerce'
			query={CommerceRead}
			variables={{ id: invoices[ 0 ].id }}>
			{( order ) => {
				
				const emailValues = getCommerceEmailValues( staff, order, subject );
				
				const sendQuickCommerceEmail = ( values ) => new Promise( async ( resolve, _ ) => {
						enqueueSnackbar( 'Please wait while the email is being sent...', { variant: 'info' } );
						
						try {
							await sendReminderCommercesEmail( invoices, timezone, values );
							enqueueSnackbar( t( 'commerce:email-sent' ), { variant: 'success' } );
						} catch ( e ) {
							console.log( e );
							enqueueSnackbar( t( 'commerce:email-not-sent' ), { variant: 'error' } );
						}
						resolve( true );
					},
				);
				
				return (
					<Form<EmailValuesType>
						initialValues={emailValues}
						onSubmit={async ( values ) => {
							sendQuickCommerceEmail( values );
							await wait( 2000 );
						}}>
						{( formik ) => {
							const toEmails = formik.values.to;
							const ccEmails = formik.values.cc;
							const bccEmails = formik.values.bcc;
							const toValidateEmails = formik.values.validateToEmails;
							const ccValidateEmails = formik.values.validateCCEmails;
							const bccValidationEmail = formik.values.validateBccEmails;
							return (
								<ModalFormWrapper
									title={`${t( 'commerce:send' )} ${startCase( toLower( order.type ) )}`}
									actionItems={[
										{
											name       : `${t( 'common:send' )}`,
											buttonProps: {
												variant : 'contained',
												color   : 'primary',
												disabled: isEmpty( toEmails ) || toValidateEmails.length > toEmails.length || ccValidateEmails.length > ccEmails.length
													|| bccEmails.length > bccValidationEmail.length
													|| toEmails.length === toValidateEmails.length && formik.values.validateToEmails.find( ( email ) => !isEmailValid( email ) )
													|| ccEmails.length === ccValidateEmails.length && formik.values.validateCCEmails.find( ( email ) => !isEmailValid( email ) )
													|| bccEmails.length === bccValidationEmail.length && formik.values.validateBccEmails.find( ( email ) => !isEmailValid( email ) ),
											},
											onClick    : () => formik.submitForm(),
										} ]}
									onSave={undefined}>
									<EmailComponent
										emailView={(
											<Paper sx={{ p: 3, bgcolor: 'background.default' }}>
												<ReminderCommercesEmailView invoices={invoices} timezone={timezone}/>
											</Paper>
										)}
									/>
								</ModalFormWrapper>
							);
						}}
					</Form>
				);
			}}
		</PageGraphqlProvider>
	);
}

export function CompanyEmailModal( { company, subject }: { company: Company, subject?: string } ) {
	const { enqueueSnackbar } = useSnackbar();
	
	const { data, isFetching } = useGraphQL<QueryStaffsReadArgs>( {
		query   : StaffsRead,
		queryKey: [ 'staffs' ],
		context : { headers: { company: company.id } },
	}, { enabled: Boolean( company.id ), keepPreviousData: true } );
	
	const staffs = data.staffsRead?.items;
	
	if ( isFetching ) return <Loading/>;
	
	return (
		<Form
			initialValues={{
				cc               : [],
				bcc              : [],
				to               : staffs?.map( ( { email } ) => email ).filter( Boolean ) || [],
				validateToEmails : [],
				validateCCEmails : [],
				validateBccEmails: [],
				note             : '',
				subject          : subject || 'Update from Invoiss',
			}}
			onSubmit={async ( values ) => {
				try {
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${company.id}/accountEmail`, {
						...values,
					} );
					enqueueSnackbar( 'Email sent', { variant: 'success' } );
				} catch ( e ) {
					console.log( e );
					enqueueSnackbar( 'The email could not be sent.', { variant: 'error' } );
				}
			}}>
			{( formik ) => {
				const toEmails = formik.values.to;
				const ccEmails = formik.values.cc;
				const bccEmails = formik.values.bcc;
				const toValidateEmails = formik.values.validateToEmails;
				const ccValidateEmails = formik.values.validateCCEmails;
				const bccValidationEmail = formik.values.validateBccEmails;
				return (
					<ModalFormWrapper
						title={`Send Email to ${company.name}`}
						saveButtonText='Send'
						saveButtonProps={{
							disabled: isEmpty( toEmails ) || toValidateEmails.length > toEmails.length || ccValidateEmails.length > ccEmails.length
								|| bccEmails.length > bccValidationEmail.length
								|| toEmails.length === toValidateEmails.length && formik.values.validateToEmails.find( ( email ) => !isEmailValid( email ) )
								|| ccEmails.length === ccValidateEmails.length && formik.values.validateCCEmails.find( ( email ) => !isEmailValid( email ) )
								|| bccEmails.length === bccValidationEmail.length && formik.values.validateBccEmails.find( ( email ) => !isEmailValid( email ) ),
						}}>
						<EmailComponent
							emailView={(
								<Paper sx={{ p: 3, bgcolor: 'background.default', maxWidth: '100%' }}>
									<AccountEmailView company={company} note={formik.values.note}/>
								</Paper>
							)}
						/>
					</ModalFormWrapper>
				);
			}}
		</Form>
	);
}

export function PaymentReceiptEmailModal( { id }: { id: string } ) {
	const { enqueueSnackbar } = useSnackbar();
	
	return (
		<PageGraphqlProvider<Payment, QueryPaymentReadArgs>
			queryKey='payment'
			query={PaymentRead}
			variables={{ id }}>
			{( payment ) => (
				<Form
					initialValues={{
						cc               : [],
						bcc              : [],
						to               : [ payment.client?.email ].filter( Boolean ),
						validateToEmails : [],
						validateCCEmails : [],
						validateBccEmails: [],
						note             : '',
						subject          : `Your transaction for ${currencyFormat( payment.amount )} to ${payment.company.name} was ${payment.status}`,
					}}
					onSubmit={async ( values ) => {
						if ( isEmpty( values.to ) ) {
							enqueueSnackbar( 'Please enter a client email address', { variant: 'default' } );
							return;
						}
						try {
							await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/emailReceipt`, {
								...values,
								payment: payment,
							} );
							enqueueSnackbar( 'Email sent', { variant: 'success' } );
						} catch ( e ) {
							console.log( e );
							enqueueSnackbar( 'The email could not be sent.', { variant: 'error' } );
						}
					}}>
					{( formik ) => {
						const toEmails = formik.values.to;
						const ccEmails = formik.values.cc;
						const bccEmails = formik.values.bcc;
						const toValidateEmails = formik.values.validateToEmails;
						const ccValidateEmails = formik.values.validateCCEmails;
						const bccValidationEmail = formik.values.validateBccEmails;
						return (
							<ModalFormWrapper
								title='Email Transaction receipt'
								saveButtonText='Send'
								saveButtonProps={{
									disabled: isEmpty( toEmails ) || toValidateEmails.length > toEmails.length || ccValidateEmails.length > ccEmails.length
										|| bccEmails.length > bccValidationEmail.length
										|| toEmails.length === toValidateEmails.length && formik.values.validateToEmails.find( ( email ) => !isEmailValid( email ) )
										|| ccEmails.length === ccValidateEmails.length && formik.values.validateCCEmails.find( ( email ) => !isEmailValid( email ) )
										|| bccEmails.length === bccValidationEmail.length && formik.values.validateBccEmails.find( ( email ) => !isEmailValid( email ) ),
								}}>
								<EmailComponent
									emailView={(
										<Paper sx={{ p: 3, bgcolor: 'background.default' }}>
											<PaymentReceiptEmailView payment={payment}/>
										</Paper>
									)}
								/>
							</ModalFormWrapper>
						);
					}}
				
				</Form>
			)}
		</PageGraphqlProvider>
	);
}

export function OpenCommercesEmailModal( {
	type,
	statuses,
	client,
}: {
	type: string,
	statuses: string[],
	client: Client
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const { isCloverDevice } = useGetDeviceInfo();
	
	let total: number | undefined;
	
	const { data: invoiceData } = useGraphQL<QueryInvoicesBalanceArgs>( {
		query    : InvoicesBalance,
		queryKey : [ 'invoicesBalance' ],
		variables: { clientId: client?.id },
	}, { enabled: Boolean( client.id ) && type === 'invoices' } );
	total = invoiceData?.invoicesBalance;
	
	const { data: estimateData } = useGraphQL<QueryEstimatesBalanceArgs>( {
		query    : EstimatesBalance,
		queryKey : [ 'estimatesBalance' ],
		variables: { clientId: client?.id },
	}, { enabled: Boolean( client.id ) && type === 'estimates' } );
	total = estimateData?.estimatesBalance;
	const { data: orderData } = useGraphQL<QueryOrdersBalanceArgs>( {
		query    : OrdersBalance,
		queryKey : [ 'ordersBalance' ],
		variables: { clientId: client?.id },
	}, { enabled: Boolean( client.id ) && type === 'orders' } );
	total = orderData?.ordersBalance;
	
	total = invoiceData?.invoicesBalance || estimateData?.estimatesBalance || orderData?.ordersBalance;
	
	const state = {
		client  : client?.id,
		statuses: statuses,
		type    : type,
	};
	
	const pdfFilters = encodeURIComponent( Buffer.from( JSON.stringify( state ) )
		.toString( 'base64' ) );
	
	return (
		<Form
			initialValues={{
				to               : [ client?.email ].filter( Boolean ),
				note             : `Hi ${client?.contact || client?.name || client?.email || 'there'}, \n${`Here are all your open ${type} until ${format( new Date(), 'PPP' )}`}`,
				cc               : [],
				bcc              : [],
				validateToEmails : [],
				validateCCEmails : [],
				validateBccEmails: [],
				subject          : `All your open ${type}`,
			}}
			onSubmit={async ( values ) => {
				try {
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${client.id}/openInvoicesOrders`, {
						isCloverDevice,
						statuses: statuses,
						type    : type,
						to      : values.to,
						note    : values.note,
						subject : values.subject,
						total   : total,
					} );
					enqueueSnackbar( `Open ${type} are sent successfully.`, { variant: 'success' } );
				} catch ( e ) {
					enqueueSnackbar( 'Something went wrong! please check your client email and resend.', { variant: 'default' } );
				}
			}}>
			{( formik ) => {
				const toEmails = formik.values.to;
				const ccEmails = formik.values.cc;
				const bccEmails = formik.values.bcc;
				const toValidateEmails = formik.values.validateToEmails;
				const ccValidateEmails = formik.values.validateCCEmails;
				const bccValidationEmail = formik.values.validateBccEmails;
				
				return (
					<ModalFormWrapper
						title={`Send open ${type}`}
						saveButtonText='Send'
						saveButtonProps={{
							disabled: isEmpty( toEmails ) || toValidateEmails.length > toEmails.length || ccValidateEmails.length > ccEmails.length
								|| bccEmails.length > bccValidationEmail.length
								|| toEmails.length === toValidateEmails.length && formik.values.validateToEmails.find( ( email ) => !isEmailValid( email ) )
								|| ccEmails.length === ccValidateEmails.length && formik.values.validateCCEmails.find( ( email ) => !isEmailValid( email ) )
								|| bccEmails.length === bccValidationEmail.length && formik.values.validateBccEmails.find( ( email ) => !isEmailValid( email ) ),
						}}>
						<EmailComponent
							emailView={(
								<Paper sx={{ p: 3, bgcolor: 'background.default' }}>
									<Fragment>
										<Stack direction='row'>
											<Box sx={{ width: '70%' }}>
												<Typography variant='h1' sx={{ fontSize: 40, pt: 1, my: 3 }}>
													{currencyFormat( total || 0 )}
												</Typography>
											</Box>
											<Box sx={{ width: '30%', textAlign: 'right' }}>
												{client.id && (
													<QRScanBox
														data={`${process.env.NEXT_PUBLIC_SITE_URL}/client/${type}?s=${encodeURIComponent( Buffer.from( JSON.stringify( {
															filters: [ {
																id   : 'client.id',
																value: {
																	$eq : client.id,
																	temp: { checked: true },
																},
															}, {
																id   : 'status',
																value: {
																	$in : statuses,
																	temp: { checked: true },
																},
															} ].filter( Boolean ),
														} ) ).toString( 'base64' ) )}`}
													/>
												)}
											</Box>
										</Stack>
										<TableWrapper>
											<TableCell sx={{ width: '100%' }}>We appreciate your business.</TableCell>
										</TableWrapper>
										{!isCloverDevice && (
											<Box mt={2}>
												<EmailButton
													target='_blank'
													href={`${process.env.NEXT_PUBLIC_SITE_URL}/client/${type}?s=${encodeURIComponent( Buffer.from( JSON.stringify( {
														filters: [ {
															id   : 'client.id',
															value: {
																$eq : client.id,
																temp: { checked: true },
															},
														}, {
															id   : 'status',
															value: {
																$in : statuses,
																temp: { checked: true },
															},
														} ].filter( Boolean ),
													} ) ).toString( 'base64' ) )}`}>
													View Details
												</EmailButton>
											</Box>
										)}
										{!isCloverDevice && (
											<Box sx={{ mt: 2 }}>
												<EmailButton
													variant='text'
													target='_blank'
													href={`${process.env.NEXT_PUBLIC_SITE_URL}/api/preview/pdf/${client.company.id}/openCommerces?s=${pdfFilters}`}>
													Download PDF
												</EmailButton>
											</Box>
										)}
									</Fragment>
								</Paper>
							)}
						/>
					</ModalFormWrapper>
				);
			}}
		
		</Form>
	);
	
}

export function ClientStatementEmailModal( {
	month,
	year,
	type,
	client,
	currentTab,
	customDates,
}: {
	month?: string,
	year?: string,
	type: string,
	client: Client,
	currentTab: number,
	customDates?: Date[]
} ) {
	const timezone = getBrowserTimezone();
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const excludePaidFromStatement = staff?.company?.metadata?.excludePaidFromStatement;
	
	const { data, isFetching } = useGraphQL<QueryCommercesStatementArgs>( {
		query    : CommercesStatement,
		queryKey : [ 'commercesStatement' ],
		variables: {
			options : { limit: 3000, filter: { timezone } },
			clientId: client.id,
			month   : month && type === 'month' ? month : undefined,
			year    : year && type === 'year' ? year : undefined,
			customDates,
			excludePaidFromStatement,
		},
	} );
	const clientInfo = client?.contact || client?.name || client?.email || 'there';
	
	const note = customDates
		? `Hi ${clientInfo}, \n Here is your statement for ${format( customDates[ 0 ], 'MMMM dd yyyy' )} - ${format( customDates[ 1 ], 'MMMM dd yyyy' )}`
		: `Hi ${clientInfo}, \n${`Here is your ${type}ly statement for${type === 'year'
			? ' the year'
			: ''} ${type === 'month' && month
			? format( new Date( month ), 'MMMM yyyy' )
			: format( addDays( new Date( year ), 1 ), 'yyyy' )}`}`;
	
	return (
		<Form
			initialValues={{
				to               : [ client.email ].filter( Boolean ),
				note,
				cc               : [],
				bcc              : [],
				validateToEmails : [],
				validateCCEmails : [],
				validateBccEmails: [],
				subject          : `Your ${customDates
					? ''
					: `${type}ly`} statement from ${data?.commercesStatement?.items[ 0 ]?.company?.name || ''}`,
			}}
			onSubmit={async ( values ) => {
				try {
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${client.id}/invoicesStatement`, {
						month  : month && type === 'month' ? month : undefined,
						year   : year && type === 'year' ? year : undefined,
						type   : type,
						currentTab,
						customDates,
						timezone,
						to     : values.to,
						subject: values.subject,
						note   : values.note,
						cc     : values.cc,
						excludePaidFromStatement,
					} );
					enqueueSnackbar( `${type
						? `${upperFirst( type )}ly`
						: 'Your'}  statement is sent successfully.`, { variant: 'success' } );
				} catch ( e ) {
					enqueueSnackbar( 'Something went wrong! please check your client email and resend.', { variant: 'default' } );
				}
				
			}}>
			{( formik ) => {
				const toEmails = formik.values.to;
				const ccEmails = formik.values.cc;
				const bccEmails = formik.values.bcc;
				const toValidateEmails = formik.values.validateToEmails;
				const ccValidateEmails = formik.values.validateCCEmails;
				const bccValidationEmail = formik.values.validateBccEmails;
				
				return (
					<ModalFormWrapper
						title='Send client statements'
						saveButtonText='Send'
						saveButtonProps={{
							disabled: isEmpty( toEmails ) || toValidateEmails.length > toEmails.length || ccValidateEmails.length > ccEmails.length
								|| bccEmails.length > bccValidationEmail.length
								|| toEmails.length === toValidateEmails.length && formik.values.validateToEmails.find( ( email ) => !isEmailValid( email ) )
								|| ccEmails.length === ccValidateEmails.length && formik.values.validateCCEmails.find( ( email ) => !isEmailValid( email ) )
								|| bccEmails.length === bccValidationEmail.length && formik.values.validateBccEmails.find( ( email ) => !isEmailValid( email ) ),
						}}>
						<CallEffect
							deps={data?.commercesStatement?.items}
							func={() => {
								if ( !isEmpty( data?.commercesStatement?.items ) ) {
									formik.setFieldValue( 'subject', `Your ${customDates
										? ''
										: `${type}ly`} statement from ${data?.commercesStatement?.items[ 0 ]?.company?.name || ''}` );
								}
							}}
						/>
						<EmailComponent
							emailView={(
								<Paper sx={{ p: 3, bgcolor: 'background.default' }}>
									{isFetching ? <Loading/> : !isEmpty( data?.commercesStatement?.items ) ? (
										<InvoicesStatementView
											invoices={data?.commercesStatement.items}
											month={month}
											year={year}
											type={type}
											customDates={customDates}
											timezone={timezone}
											excludePaidFromStatement={excludePaidFromStatement}
										/>
									) : <Typography textAlign='center'>No documents were found for this client.</Typography>}
								</Paper>
							)}
						/>
					</ModalFormWrapper>
				);
			}}
		
		</Form>
	);
	
}

export function HouseAccountInvoiceEmailModal( {
	month,
	houseAccount,
	email,
	timeZone,
	invoices,
}: {
	month: string,
	houseAccount: HouseAccount,
	email: string,
	timeZone: string,
	invoices: Order[]
} ) {
	const { enqueueSnackbar } = useSnackbar();
	
	const timePeriod = month === 'AllMonths'
		? `Until ${format( new Date(), 'MMMM yyyy' )}`
		: format( new Date( month ), 'MMMM yyyy' );
	
	const { data: totalData } = useGraphQL<QueryHouseAccountInvoicesTotalsByMonthArgs>( {
		query    : gql`
			query HouseAccountInvoicesTotalsByMonth_7d5a($houseAccountId: String!, $month: DateTime,$isAllMonths: Boolean) {
				houseAccountInvoicesTotalsByMonth(houseAccountId: $houseAccountId, month: $month,isAllMonths: $isAllMonths) {
					balance
				}
			}
		`,
		queryKey : [ 'houseAccountInvoicesTotalsByMonth' ],
		variables: {
			houseAccountId: houseAccount.id,
			isAllMonths   : month === 'AllMonths',
			month         : month === 'AllMonths'
				? '12-01-2023'
				: month,
		},
		
	}, { enabled: Boolean( houseAccount.id ) } );
	
	const client = houseAccount.client;
	const receiver = houseAccount.contact || houseAccount.name || houseAccount.email || client?.contact || client?.name || client?.email;
	
	return (
		<Form
			initialValues={{
				to               : [ email ].filter( Boolean ),
				note             : `Hi ${receiver || 'there'}, \n${`Here is your monthly invoice statement for ${timePeriod}`}`,
				cc               : [],
				bcc              : [],
				validateToEmails : [],
				validateCCEmails : [],
				validateBccEmails: [],
				subject          : `Your monthly invoice statement from ${invoices[ 0 ]?.company?.name || ''}`,
			}}
			onSubmit={async ( values ) => {
				try {
					if ( isEmpty( invoices ) ) throw 'No invoices to email';
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${invoices[ 0 ].company.id}/houseAccountInvoiceStatement`, {
						month         : month,
						houseAccountId: houseAccount.id,
						to            : values.to,
						subject       : values.subject,
						note          : values.note,
						cc            : values.cc,
						timeZone      : timeZone,
						
					} );
					enqueueSnackbar( 'Monthly invoice statement is sent successfully.', { variant: 'success' } );
				} catch ( e ) {
					enqueueSnackbar( 'Something went wrong! please check your house account email and resend.', { variant: 'default' } );
				}
				
			}}>
			{( formik ) => {
				const toEmails = formik.values.to;
				const ccEmails = formik.values.cc;
				const bccEmails = formik.values.bcc;
				const toValidateEmails = formik.values.validateToEmails;
				const ccValidateEmails = formik.values.validateCCEmails;
				const bccValidationEmail = formik.values.validateBccEmails;
				return (
					<ModalFormWrapper
						title='Send client statements'
						saveButtonText='Send'
						saveButtonProps={{
							disabled: isEmpty( toEmails ) || toValidateEmails.length > toEmails.length || ccValidateEmails.length > ccEmails.length
								|| bccEmails.length > bccValidationEmail.length
								|| toEmails.length === toValidateEmails.length && formik.values.validateToEmails.find( ( email ) => !isEmailValid( email ) )
								|| ccEmails.length === ccValidateEmails.length && formik.values.validateCCEmails.find( ( email ) => !isEmailValid( email ) )
								|| bccEmails.length === bccValidationEmail.length && formik.values.validateBccEmails.find( ( email ) => !isEmailValid( email ) ),
						}}>
						<EmailComponent
							emailView={(
								<Paper sx={{ p: 3, bgcolor: 'background.default' }}>
									{!isEmpty( invoices ) ? (
										<HouseAccountInvoicesStatement
											invoices={invoices}
											houseAccount={houseAccount}
											month={month}
											balance={totalData?.houseAccountInvoicesTotalsByMonth?.balance}
											houseAccountId={houseAccount.id}
										/>
									) : <Typography variant='h5' textAlign='center'>No invoices to send.</Typography>}
								</Paper>
							)}
						/>
					</ModalFormWrapper>
				);
			}}
		</Form>
	);
	
}

